@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Serif'), local('NotoSerif'),
    url('../font/noto-serif-v7-latin-regular.woff2') format('woff2'),
    url('../font/noto-serif-v7-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../font/open-sans-v16-latin-regular.woff2') format('woff2'),
    url('../font/open-sans-v16-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('../font/open-sans-v16-latin-italic.woff2') format('woff2'),
    url('../font/open-sans-v16-latin-italic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../font/open-sans-v16-latin-700.woff2') format('woff2'),
    url('../font/open-sans-v16-latin-700.woff') format('woff');
}