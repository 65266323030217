/* START: DESKTOP & MOBILE CSS for ALL pages */

/* RESET - http://meyerweb.com/eric/tools/css/reset/ */
html, body, applet, object, iframe, svg, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, figure{
    margin:0; padding:0; border:0; font-size:100%; font-weight:inherit; font-style:inherit;
    font-family:inherit; text-decoration:inherit; background:transparent;
}
header, footer, figure, details, hgroup, section, article, nav, aside{display:block;}
label{display:inline-block; position:relative; width:100%;}
object, audio, canvas, iframe, img, svg, video{vertical-align:middle; width:100%;}

/* CSS Global improvements */
*{box-sizing:border-box;}
svg{fill:currentColor;}
strong{font-weight:700;}
a{color:#337ab7; transition-duration:0.25s; text-decoration:none;}
a:hover, a:focus{color:#247; text-decoration:underline; outline:none;}

html, body, #root, .main{width:100%; height:100%; text-align:center;}
body, input, button, textarea{font-family:"Open sans","Helvetica Neue", Helvetica, Arial, sans-serif; font-size:1rem;}
body{line-height:1.35; color:#000;}
.regular{font-weight:400;}
.bold{font-weight:700;}
.italic{font-style:italic;}
.h1{font-size:2.25rem; line-height:1.05; letter-spacing:-.375px;}
.h2{font-size:1.975rem; line-height:1.15; letter-spacing:-.25px;}
.h3{font-size:1.725rem; line-height:1.15; letter-spacing:1px;}
.h4{font-size:1.425rem; line-height:1.25; letter-spacing:0;}
.h5{font-size:1.175rem; line-height:1.25; letter-spacing:0;}
.h6{font-size:1rem; line-height:1.5; letter-spacing:0;}
.small{font-size:.85rem; line-height:1.5;}
small, .smallest{font-size:.75rem;}
.wrap{padding:10px 15px; margin:auto;}

/* CLEARFIX */
.cl:before, .cl:after{content:" "; display:table;}
.cl:after{clear:both;}

/* BASIC CLASSES */
.no-li, .no-li ul{padding-left:0; list-style:none;}
body .hide{display:none;}

/* FORMS */
.form{width:100%;}
.input input, .textarea{transition:all .1s linear; outline:none;}
.input input, .btn{height:46px; padding:10px; border:none; border-radius:3px;}
.textarea{padding:10px; border-radius:3px;}
.input > *{width:100%;}
.input input{font-size:18px; width:100%;}
.input input::placeholder{color:#bbb;}
.input input:focus, .textarea:focus{border:1px solid #ffaa08;} /* inset emulate 3px border*/
.input input:focus{box-shadow:inset 2px 0 0 #ffaa08, inset -2px 0 0 #ffaa08, inset 0 2px 0 #ffaa08, inset 0 -2px 0 #ffaa08;}
.textarea:focus{box-shadow:inset 3px 0 0 #ffaa08, inset -3px 0 0 #ffaa08, inset 0 3px 0 #ffaa08, inset 0 -3px 0 #ffaa08;}
.btn{font-family:'Open Sans', sans-serif; font-weight:bold; border-radius:30px; display:inline-block; font-size:1.15rem; cursor:pointer; border-radius:30px; padding:15px 20px; text-decoration:none!important; transition:all .2s ease; height:auto; width:100%;}
.btn.small{font-size:1rem; padding:3px 15px; height:initial;}
.btn.white,.btn.white:visited{font-size:1.1rem; background:#fff; color:#00426b; height:auto;}
.btn.white:hover, .btn.white:active{text-decoration:none; outline:0;}
.btn.blue,.btn.blue:visited{font-size:1rem; background:#0234AD; color:#fff; height:auto;}
.btn.blue:hover, .btn.blue:active{text-decoration:none; outline:0;}
.btn.yellow,.btn.yellow:visited{font-size:1.2rem; background:#ffba00 linear-gradient(#fff 0,#ffe6a0 2%,#ffba00 100%); color:#222; height:auto; border: 1px solid #d58512; font-weight: bold; text-shadow:0 0 2px rgba(255,255,255,.5)}
.btn.yellow:hover, .btn.yellow:active{text-decoration:none; outline:0;}
.btn.upper{text-transform:uppercase;}

/* HEADER with LOGO & menu icon */
.header .logo svg{fill:#fff;} /* Fix firefox */
.header .logo .svg{display:inline-block;height:50px;fill:#fff;width:180px;filter:drop-shadow(2px 2px 3px rgba(0,0,0,.3));}

/* DESIGN LAYOUT */
.main{background: rgb(20,94,137);
	background: linear-gradient(90deg, rgba(20,94,137,1) 0%, rgba(88,161,208,1) 100%); color:#fff;}
.logo{position:absolute; width:100%; display:flex; justify-content:center; align-items:center; height:80px; background:transparent;}
.page{min-height:100vh; padding:10px 20px;}
.page.vertical{display:flex; justify-content:center; align-items:center; flex-direction:column;}
.page .title{font-family:'Helvetica Neue',Helvetica,Arial,sans-serif; text-shadow:0 1px 2px rgba(0,0,0,.6); font-size: 2em;}
.box{width:100%; margin:1em 0 1em;}
.par{text-shadow:0 1px 2px rgba(0,0,0,.6);font-size: 1.2rem;}
.btn-list .btn{margin:8px 0;}
.back{display:block; position:absolute; top:20px; left:14px; background:none; border:0;}
.back .svg, .back svg{color:#fff; }
.back .svg{width:22px; height:22px; transform:rotate(-90deg); opacity:.4;}

.error{background:#d00; margin-top:5px; padding:5px;}

/*.DayPickerInput */
body .DayPicker{color:#000; background:#fff; border-radius:4px;}
body .DayPicker-wrapper{padding:0;}
body .DayPicker-Month{margin:5px; width:100%;}
body .DayPicker-Day{padding:5px 2px; border-radius:5px;}
body .DayPicker-Day--selected{background-color:#ffd243!important; color:#000!important; font-weight:bold;}
body .DayPicker-Day--today{color:#000;font-weight:400;}
body .DayPicker-Caption{margin-bottom:0;}
body .DayPicker-NavButton{top:6px; right:10px;}
body .DayPickerInput-Overlay{width:100%; box-shadow:4px 4px 5px rgba(0,0,0,.3);}
body .DayPicker{width:100%;}
body .DayPickerInput-OverlayWrapper{top:-2px;}
body .DayPicker-Caption select{font-size:16px; margin-right:5px; padding:5px; text-transform:capitalize;}
.date{font-size:21px; margin-bottom:10px;}
.date .set{text-transform:capitalize;}


/* HOME */
.home{display:flex; color:#fff;}
.home .img{margin-bottom:20px; max-width: 20em;}
.privacy{color:#fff; margin-top:2em;}
.privacy .lnk{color:#fafafa; text-decoration:underline;}


/* Summary Settlement */
.totalSettlement{display:flex; justify-content:center; align-items:center; font-weight: bold;}
.currency{margin:2px 0 0 4px;}
.result{margin-top:10px;}
.countdown{font-size:1.5em;margin:20px 0 10px;text-shadow:0 1px 2px rgba(0,0,0,.6);}

/* Extra Small Devices, Phones, Tablets */
@media only screen and (max-width:767px){
	.wrap{max-width:768px;}
	.input input{width:100%;}
}

/* Small Devices, Tablets */
@media only screen and (min-width:768px){
	.wrap{max-width:740px;}
	.btn, .box, .input input{max-width:380px;}
}

@media only screen and (min-width:992px){
	.wrap{max-width:960px;}
	.btn{padding:15px 60px;}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width:1200px){
	.wrap{max-width:1000px;}
}

@media only screen and (max-height:600px) and (max-width:400px){
.h1{font-size:1.975rem; line-height:1.05; letter-spacing:0;}
.h2{font-size:1.725rem; line-height:1.15; letter-spacing:0;}
.h3{font-size:1.425rem; line-height:1.15; letter-spacing:0;}
.h4{font-size:1.175rem; line-height:1.25;}
.h5{font-size:1rem; line-height:1.25;}
.h6{font-size:1rem; line-height:1.5; letter-spacing:0;}
.home .img{width:200px; height:200px;}
.btn{padding:10px 20px;}
.box{margin:10px 0;}
}
